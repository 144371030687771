/* eslint-disable no-underscore-dangle */
import React from 'react';
import Navigation from '../components/navigation';
import './style/style.scss';

const Layout = (props) => (
  <Navigation>
    <main className="container-fluid p-0">{props.children}</main>
  </Navigation>
);

export default Layout;
