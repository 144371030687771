import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'gatsby';
import Logo from '../../../static/images/logo-onai-intelligence.svg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="row m-0  justify-content-center">
      <div className="col-md-2 mr-auto p-1 font-weight-bold text-center">
        <NavbarBrand className="m-0 p-2" href="/">
          <img alt="logo" className="logo-one m-0" src={Logo} />
        </NavbarBrand>
      </div>
      <div className="col-md-8 align-self-center">
        <Navbar color="transparent" light expand="md">
          <NavbarToggler onClick={toggle} />
          <Collapse className="bg-white" isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/web-development/">Web</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/information-technology">Technology</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/digital-services">Digital</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://your.onai.space">Personalised IT</NavLink>
              </NavItem>
              {/* <NavItem> */}
              {/*  <NavLink href="/blog">Insights</NavLink> */}
              {/* </NavItem> */}
              <Link className="btn-outline-dark ml-2 rounded-0 text-uppercase btn btn-lg" to="/contact">
                book a chat
              </Link>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
