import React from 'react';
import { Link } from 'gatsby';
import config from '../../../config/siteConfig';
import './style.scss';
import Social from '../../templates/social';

const Footer = () => {
  return (
    <footer className="footer bg-neutralLighterAlt p-4 col-md-12 mx-auto">
      <div className="row container  mx-auto">
        <div className="col">
          <ul className="nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                Web
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/categories">
                Digital
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">
                Technology
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Personalised IT
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-auto col-6">
          <Social />
        </div>
      </div>
      <p className="small p-3 text-muted">{config.copyright}</p>
    </footer>
  );
};

export default React.memo(Footer);
