import React from 'react';
import config from '../../config/siteConfig';

const Social = () => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="row align-content-center justify-content-center">
      {config.rrssb.map(item => (
        <div className="p-2">
          <a
            href={item.url}
            key={item.label}
            className="p-3 align-self-center text-center col-2"
            target="_top"
            rel="noopener noreferrer"
            aria-label={item.label}
          >
            <i className={`${item.iconClassName}`} />
          </a>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Social);
